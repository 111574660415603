@import "variables";
@import "reset";
@import "fonts";

html {
  overflow-x: hidden;
}

.temp-text {
  background-color: lightgoldenrodyellow;
}

.pin-content {
  border: 3px transparent rgb(250, 250, 250);
  background-color: $white;

  &.is-active {
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
      position: relative;
    }
  }
}

html, body {
  width: 100vw;
  background: $black;
  position: relative;

  &.modal-active {
    overflow: hidden;
  }
}

a {
  color: $blue;

  &:hover {
    text-decoration: none;
  }
}

.app {
  width: 100%;
  max-width: $xxl;
  margin: 0 auto;
  background-color: $white;

  .no-br {
    white-space: nowrap;
  }
}

.is-win .btn-red.video svg {
  margin-left: -15px;
}

.btn-white {
  border: 2px solid $white;
  border-radius: 30px;
  text-decoration: none;
  padding: 12px 30px;
  font-weight: 800;
  display: inline-block;
  margin-top: 15px;

  &:hover {
    background-color: $red;
  }
}

.btn-red {
  border: 2px solid $red;
  border-radius: 30px;
  background-color: $red;
  color: $white;
  text-decoration: none;
  padding: 12px 30px;
  font-weight: 800;
  display: inline-block;
  margin-top: 30px;

  &.video {
    padding: 4px 10px;
    display: flex;
    max-width: 200px;
    align-items: center;
    font-size: 1rem;

    span {
      padding: 0 15px;
      white-space: nowrap;

      svg {
        margin-top: 2px;

        path {
          fill: $white;
        }

        g {
          stroke: $white;
        }
      }
    }

    &:hover {
      svg path {
        fill: $red;
      }

      svg g {
        stroke: $red;
      }
    }
  }

  &:hover {
    background-color: $white;
    color: $red;
  }
}

.btn-watch {
  text-decoration: none;
  font-size: 1.2rem;
  line-height: 1.8rem;
  display: inline-block;
  position: relative;
  color: $red;
  font-family: $fontBlack;
  font-weight: 800;
  border: none;
  border-bottom: 2px solid $red;
  background: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;

  &::after {
    position: absolute;
    top: 0;
    right: -40px;
    content: "";
    background-image: url("../images/icon-video-play.svg");
    width: 34px;
    height: 34px;
    background-size: cover;
  }

  &:hover {
    border-bottom: 2px solid $white;
  }
}

.backslash {
  margin-right: -5px;
}

/* ANIMATION CLASSES */

.anim-fadein,
.anim-fadein-long,
.anim-fadedown,
.anim-fadeleft,
.anim-faderight,
.anim-faderight-long {
  opacity: 0;
  visibility: hidden;
  display: block;
}

.not-mobile .anim-fadedown {
  margin-top: -50px;
}

.not-mobile .anim-fadeleft {
  margin-left: 50px;
}

.not-mobile .anim-faderight {
  margin-left: -50px;
}

/* MODAL CLASSES */

.body-blackout {
  position: fixed;
  z-index: 1010;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: none;

  &.is-blacked-out {
    display: block;
  }
}

.popup-trigger {
  display: inline-block;
  cursor: pointer;
}

.popup-modal {
  height: 45vw;
  width: 75vw;
  background-color: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 30px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 300ms ease-in-out;
  z-index: 1011;

  @media screen and (max-width: $xl) {
    height: 46vw;
  }

  @media screen and (max-width: $md) {
    height: 48vw;
  }

  @media screen and (max-width: $sm) {
    width: 85vw;
    height: 56vw;
    padding: 15px;
  }

  &.is-visible {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }

  &__close {
    position: absolute;
    display: block;
    font-size: 2.4rem;
    font-style: normal;
    line-height: 0;
    right: -20px;
    top: -20px;
    cursor: pointer;
    z-index: 10;
    color: $red;
    height: 44px;
    width: 44px;
    background: white;
    border-radius: 30px;
    border: 2px solid $red;
    font-weight: 500;
    padding: 15px 12px;

    @media screen and (max-width: $sm) {
      font-size: 2rem;
      padding: 16px 12.5px;
    }

    &:hover {
      background-color: $red;
      color: $white;
    }
  }

  .video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 25px;
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/* CONTENT CLASSES */

.main-header {
  color: $white;
  position: relative;
  width: 100%;
  background-color: $black;
  height: 102vh;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 400px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 0, 0, 0) 98%);
    z-index: 1;
  }

  video {
    height: 102vh;
    width: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    left: auto;
    top: 0;
    margin: 0 auto;
    z-index: 0;
    background-image: url("../images/hero-poster.jpg");
    background-position: center;
    background-size: cover;
  }

  img.logo {
    position: absolute;
    top: 1.875rem;
    left: 3.125rem;
    z-index: 2;

    @media screen and (max-width: $sm) and (orientation: portrait) {
      width: 200px;
      top: 20px;
      left: 50%;
      margin-left: -100px;
    }

    @media screen and (max-width: $sm) and (orientation: landscape) {
      width: 200px;
      top: 20px;
      left: 20px;
    }
  }

  .primary {
    position: absolute;
    top: 45vh;
    width: 100%;
    padding: 0 1.5rem;

    @media screen and (max-width: $sm) and (orientation: portrait) {
      top: 40vh;
    }

    @media screen and (max-width: $sm) and (orientation: landscape) {
      top: 38vh;
    }

    h2 {
      font-size: 6rem;
      line-height: 6.8rem;
      text-align: center;

      @media screen and (max-width: $md) {
        font-size: 2.4rem;
        line-height: 3rem;
      }

      span {
        display: block;

        &.strong {
          text-transform: uppercase;
          font-family: $fontBlack;
          font-weight: 800;
        }
      }
    }
  }

  .secondary {
    position: absolute;
    top: 55vh;
    width: 100%;
    padding: 0 1.5rem;
    text-align: center;
    opacity: 0;
    visibility: hidden;

    @media screen and (max-width: $md) {
      top: 45vh;
    }

    h1 {
      font-family: $fontBlack;
      font-weight: 800;
      letter-spacing: 0.15rem;
      line-height: 2.4rem;

      @media screen and (max-width: $md) {
        font-size: 1.8rem;
        margin-top: -30px;
      }

      @media screen and (max-width: $sm) {
        font-size: 1.4rem;
        margin-top: -40px;
      }

      span {
        display: block;
      }
    }

    p {
      font-size: 2rem;
      line-height: 2.2rem;
      margin: 20px auto;
      width: 80%;
      max-width: 780px;

      span {
        font-weight: 800;
      }

      @media screen and (max-width: $md) {
        font-size: 1.8rem;
        line-height: 2rem;
        width: 85%;
      }

      @media screen and (max-width: $sm) {
        font-size: 1.4rem;
        line-height: 1.6rem;
      }
    }
  }
}

.is-mobile {
  .main-header {
    .primary {
      top: 20vh;
      opacity: 1;
      z-index: 30;
    }

    .secondary {
      visibility: visible;
      opacity: 1;
      z-index: 30;

      h1 {
        line-height: 1.6rem;
      }
    }
  }
}

.scroll-message {
  position: relative;
  color: $white;
  display: block;
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
  bottom: 150px;
  text-align: center;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 800;
  z-index: 3;

  @media screen and (max-width: $md) {
    bottom: 180px;
    font-size: 0.9rem;
    font-family: $fontBlack;
    font-weight: 800;
    letter-spacing: 0.2rem;
  }

  @media screen and (max-width: $sm) and (orientation: landscape) {
    bottom: 100px;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 40px;
    left: 50%;
    margin-left: -20px;
    background-image: url("../images/icon-scroll.svg");
    height: 40px;
    width: 40px;

    @media screen and (max-width: $md) {
      top: 30px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 50%;
    display: block;
    top: 80px;
    height: 58vh;
    width: 1px;
    border-left: 2px solid $red;

    @media screen and (max-width: $md) {
      height: 170px;
      top: 70px;
    }
  }
}

footer {
  background-color: $black;
  color: $white;
  padding: 60px 100px;

  @media screen and (max-width: $md) {
    padding: 60px 40px;
  }

  @media screen and (max-width: $sm) {
    padding: 60px 30px;
  }

  .top {
    display: flex;
    width: 100%;

    @media screen and (max-width: $sm) {
      display: block;
      text-align: center;
    }

    .logo {
      width: 215px;

      @media screen and (max-width: $sm) {
        width: 100%;
        margin-bottom: 30px;

        img {
          width: 215px;
        }
      }
    }

    .social {
      width: 100%;

      ul {
        display: flex;
        justify-content: flex-end;

        @media screen and (max-width: $sm) {
          justify-content: center;
        }

        li {
          margin-left: 20px;

          a {
            &:hover {
              svg path {
                fill: $gray;
              }
            }
          }
        }
      }
    }
  }

  .bottom {
    display: flex;
    width: 100%;
    margin-top: 30px;

    @media screen and (max-width: $lg) {
      display: block;
    }

    .copyright {
      width: 315px;

      @media screen and (max-width: $lg) {
        width: 100%;
      }

      @media screen and (max-width: $sm) {
        text-align: center;
      }
    }

    .links {
      width: 100%;

      @media screen and (max-width: $lg) {
        margin-top: 20px;
      }

      ul {
        display: flex;
        justify-content: flex-end;

        @media screen and (max-width: $lg) {
          justify-content: flex-start;
        }

        @media screen and (max-width: 481px) {
          margin-left: 0;
        }

        @media screen and (max-width: $sm) {
          justify-content: center;
          flex-wrap: wrap;
          text-align: center;
          margin-left: -20px;
          list-style: none;
        }

        li {
          margin-left: 20px;
          position: relative;
          padding-left: 12px;
          padding-bottom: 12px;
          font-size: 1.2rem;

          @media screen and (max-width: $md) {
            margin-left: 15px;
            padding-left: 15px;
          }

          @media screen and (max-width: $lg) {
            font-size: 1rem;
          }

          @media screen and (max-width: $sm) {
            padding-left: 20px;
            font-size: 0.9rem;
          }

          &:first-child {
            margin-left: 0;
            padding-left: 0;
          }

          &:last-child {
            &::after {
              display: none;
            }
          }

          &::after {
            content: "|";
            position: absolute;
            right: -20px;
            top: 0;
            display: block;
          }

          a {
            color: $white;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }

          @media screen and (max-width: 481px) {
            &:nth-child(2) {
              &::after {
                display: none;
              }
            }
          }

          @media screen and (min-width: 481px) and (max-width: 628px) {
            &:nth-child(3) {
              &::after {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.back-to-top {
  background-color: $red;
  padding: 60px 40px 40px;
  text-align: center;

  a {
    display: inline-block;
    position: relative;
    color: $white;
    font-size: 1.5rem;
    text-decoration: none;

    @media screen and (max-width: $sm) {
      font-size: 1.2rem;
    }

    &:hover {
      text-decoration: underline;
    }

    &::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 50%;
      margin-left: -10px;
      width: 20px;
      height: 11px;
      background: url("../images/icon-chevron.svg");
      background-size: 100%;
      background-repeat: none;
      transform: rotate(180deg);
    }
  }
}

section {
  position: relative;
  scrollbar-width: thin;
  scrollbar-color: $red $pink;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 8px;
    background-color: $pink;
  }

  &::-webkit-scrollbar-track:hover {
    background-color: $pink;
  }

  &::-webkit-scrollbar-track:active {
    background-color: $pink;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: $red;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #c7011e;
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: $red;
  }

  h2 {
    font-size: 4rem;
    text-transform: uppercase;
    font-family: $fontBlack;
    font-weight: 800;
    color: $red;
    margin: 0 auto 80px;
    line-height: 4.2rem;
    letter-spacing: 0.4rem;

    @media screen and (max-width: $lg) {
      font-size: 3.5rem;
    }

    @media screen and (max-width: $md) {
      font-size: 2.1rem;
      line-height: 2.8rem;
      letter-spacing: 0.1rem;
    }

    @media screen and (max-width: $sm) {
      font-size: 2.5rem;
      line-height: 2.8rem;
      letter-spacing: 0.4rem;
      margin-bottom: 30px;
    }
  }

  .revealing-header-mask {
    overflow: hidden;
    display: block;
  }

  .revealing-header-mask-content {
    display: block;
    transform: translateY(100%);
    transition: transform 1.2s cubic-bezier(0.16, 1.08, 0.38, 0.98) 0.1s;
    will-change: transform;
  }

  .revealing-header-mask-content.is-active {
    transform: translateY(0%);
  }

  .revealing-header-mask:nth-child(1) .revealing-header-mask-content {
    transition-delay: calc(0.1s + 0s);
  }

  .revealing-header-mask:nth-child(2) .revealing-header-mask-content {
    transition-delay: calc(0.1s + 0.15s);
  }

  .revealing-header-mask:nth-child(3) .revealing-header-mask-content {
    transition-delay: calc(0.1s + 0.3s);
  }

  .revealing-header-mask:nth-child(4) .revealing-header-mask-content {
    transition-delay: calc(0.1s + 0.45s);
  }

  .revealing-header-mask:nth-child(5) .revealing-header-mask-content {
    transition-delay: calc(0.1s + 0.6s);
  }

  .revealing-header-mask:nth-child(6) .revealing-header-mask-content {
    transition-delay: calc(0.1s + 0.75s);
  }

  .revealing-header-mask:nth-child(7) .revealing-header-mask-content {
    transition-delay: calc(0.1s + 0.9s);
  }

  .revealing-header-mask:nth-child(8) .revealing-header-mask-content {
    transition-delay: calc(0.1s + 1.05s);
  }

  .revealing-header-mask:nth-child(9) .revealing-header-mask-content {
    transition-delay: calc(0.1s + 1.2s);
  }

  .revealing-header-mask:nth-child(10) .revealing-header-mask-content {
    transition-delay: calc(0.1s + 1.35s);
  }

  h3 {
    position: relative;
    font-weight: 800;
    font-size: 2rem;
    display: block;
    margin-bottom: 3rem;

    @media screen and (max-width: $sm) {
      font-size: 1.6rem;
      padding: 0;
    }

    &::after {
      position: absolute;
      content: "";
      background-color: $red;
      width: 44px;
      height: 6px;
      bottom: -30px;
      left: 0;
      margin-left: 0;
    }

    & + p {
      font-size: 1.6rem;
      letter-spacing: 0.08rem;
      line-height: 2rem;
      width: 75%;

      @media screen and (max-width: $lg) {
        width: 100%;
      }

      @media screen and (max-width: $sm) {
        font-size: 1.3rem;
        line-height: 1.8rem;
      }
    }

    &.center {
      text-align: center;

      &::after {
        left: 50%;
        margin-left: -22px;
      }

      & + p {
        text-align: center;
        margin: 0 auto;
      }
    }
  }

  h4 {
    text-transform: uppercase;
    font-family: $fontBlack;
    font-weight: 800;
    font-size: 1.2rem;
    letter-spacing: 0.2rem;
    display: block;
    margin-bottom: 1rem;
    line-height: 1.8rem;

    @media screen and (max-width: $md) {
      font-size: 1rem;
    }
  }

  &.section-declarations {
    .wrapper {
      background-color: $white;
      padding-bottom: 280px;
    }

    .declarations {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      max-width: 1900px;
      margin: -22px auto;

      @media screen and (max-width: $md) {
        display: block;
        padding-top: 40px;
      }

      li {
        display: block;
        width: 50%;
        text-align: center;
        padding: 8vw 5vw;
        position: relative;
        background-color: $white;

        @media screen and (max-width: $md) {
          width: 100%;
          padding: 40px 30px 60px;
        }

        &:last-child {
          width: 100%;
          position: relative;
          background-color: $white;
          z-index: 5;
        }

        .contents {
          & > span {
            position: relative;
            text-transform: uppercase;
            font-family: $fontBlack;
            font-weight: 800;
            font-size: 1.2rem;
            letter-spacing: 0.2rem;
            display: block;
            margin-bottom: 3rem;

            &::after {
              position: absolute;
              content: "";
              background-color: $red;
              width: 44px;
              height: 6px;
              bottom: -30px;
              left: 50%;
              margin-left: -22px;
            }
          }

          p {
            font-size: 1.8rem;
            line-height: 2.4rem;
            display: block;
            padding: 0 60px;

            @media screen and (max-width: $md) {
              padding: 0 10px;
            }

            strong {
              display: block;
            }
          }

          /*
          &::before {
            content: "";
            position: absolute;
            left: 50%;
            display: block;
            top: -60vw;
            height: 60vw;
            width: 1px;
            border-left: 2px solid $red;

            @media screen and (min-width: $sm) {
              top: -60vw;
              height: 60vw;
            }

            @media screen and (min-width: $md) {
              top: -40vw;
              height: 40vw;
            }

            @media screen and (min-width: $lg) {
              top: -18vw;
              height: 20vw;
            }
          }
          */
        }
      }
    }
  }

  &.section-call {
    z-index: 5;
    margin: -200px 8vw 50px;

    .container {
      display: flex;
      align-items: center;

      @media screen and (max-width: $md) {
        display: block;
        position: relative;
        height: 300px;
      }

      a {
        color: $red;
        font-family: $fontBlack;
        font-weight: 800;
        font-size: 2rem;

        .letters {
          font-size: 1.8rem;
        }
      }

      img {
        width: 265px;
        text-align: left;

        @media screen and (max-width: $md) {
          position: absolute;
          width: 250px;
          left: -100px;
          top: 60px;
        }
      }

      p {
        color: $red;
        padding-left: 30px;
        font-size: 1.6rem;
        line-height: 2rem;

        @media screen and (max-width: $md) {
          text-align: center;
        }
      }
    }
  }

  &.section-lrg-headline {
    z-index: 1;
    margin-top: -75px;
    padding: 10vw;
    background-color: $ltblue;

    @media screen and (max-width: $md) {
      padding: 60px;
    }

    @media screen and (max-width: $sm) {
      padding: 60px 15px;
    }

    h2 {
      text-align: center;

      .revealing-header-mask {
        display: block;
      }
    }

    .reason-1 {
      .image-left {
        display: flex;
        margin: 100px auto 80px;
        max-width: 1100px;
        border-radius: 30px;
        background-color: $white;

        @media screen and (max-width: $sm) {
          display: block;
        }

        .image {
          width: 50%;
          position: relative;

          @media screen and (max-width: $sm) {
            width: 100%;
          }

          img {
            position: absolute;
            object-fit: cover;
            border-radius: 30px 0 0 30px;
            height: 100%;
            width: 100%;

            @media screen and (max-width: $sm) {
              position: relative;
              border-radius: 30px 30px 0 0;
            }
          }
        }

        p {
          width: 50%;
          padding: 60px;
          font-size: 1.2rem;
          line-height: 1.6rem;
          letter-spacing: 0.04rem;

          @media screen and (max-width: $sm) {
            width: 100%;
            padding: 30px;
            font-size: 1rem;
          }
        }
      }
    }
  }

  &.section-meet {
    position: relative;
    z-index: 3;
    margin: -80px auto 400px;
    display: flex;
    align-items: center;
    width: 90%;
    max-width: 1130px;
    padding-left: 90px;

    @media screen and (max-width: $md) {
      flex-direction: column-reverse;
      padding-left: 0;
      margin-bottom: 100px;
    }

    & > div {
      width: 50%;

      @media screen and (max-width: $md) {
        width: 100%;
        padding-left: 0;
      }

      &.left {
        position: relative;
        padding-right: 25px;
        padding-top: 60px;

        @media screen and (max-width: $md) {
          padding-left: 12px;
        }

        p {
          font-size: 1.2rem;
          letter-spacing: 0.05rem;
          line-height: 1.6rem;
        }
      }

      &.right {
        position: relative;

        @media screen and (max-width: $md) {
          text-align: center;
        }

        .btn-watch {
          position: absolute;
          bottom: -30px;
          left: 75px;

          @media screen and (max-width: $md) {
            position: relative;
            bottom: auto;
            left: auto;
            margin-left: -20px;
          }
        }

        .mask {
          width: 385px;
          margin-left: -60px;
          -webkit-mask-image: radial-gradient(circle, black 50%, rgba(0, 0, 0, 0%) 50%);
          mask-image: radial-gradient(circle, black 50%, rgba(0, 0, 0, 0%) 50%);

          @media screen and (max-width: $md) {
            margin: 0 auto;
          }
        }
      }
    }
  }

  &.section-hero-text-above {
    .reason-2 {
      position: relative;

      .copy {
        position: relative;
        position: absolute;
        z-index: 3;
        top: -200px;
        right: 0;
        width: 66%;
        background: $white;
        padding: 40px 60px 60px;

        @media screen and (max-width: $md) {
          width: 100%;
          position: relative;
          top: auto;
          right: auto;
          padding: 40px 30px 60px;
        }

        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 1px;
          height: 250px;
          left: 0;
          top: -180px;
          border-left: 2px solid $red;

          @media screen and (max-width: $md) {
            left: 30px;
            top: -80px;
            height: 80px;
          }
        }
      }
    }
  }

  &.section-list-bubble {
    .reason {
      display: flex;
      margin: 100px auto;
      width: 90%;
      max-width: 1100px;

      @media screen and (max-width: $lg) {
        max-width: 950px;
      }

      @media screen and (max-width: $md) {
        display: block;
        width: 100%;
        padding: 0 30px;
      }

      div {
        &.copy {
          width: 52%;

          @media screen and (max-width: $md) {
            width: 100%;
          }

          h3 + p {
            width: 90%;

            @media screen and (max-width: $md) {
              width: 100%;
            }
          }
        }

        &.bullet-details {
          width: 48%;
          border-radius: 30px;
          background-color: $ltblue;
          padding: 40px;

          @media screen and (max-width: $md) {
            margin-top: 40px;
            display: block;
            width: 100%;
          }

          p {
            font-size: 1.4rem;
            width: 85%;

            @media screen and (max-width: $md) {
              width: 100%;
              font-size: 1.2rem;
              line-height: 1.4rem;
            }
          }

          ul {
            margin-top: 40px;
            column-count: 2;
            column-gap: 70px;

            li {
              font-size: 1.3rem;
              margin-bottom: 14px;
              font-weight: 800;

              @media screen and (max-width: $md) {
                font-size: 1.2rem;
              }
            }
          }
        }
      }
    }
  }

  &.section-image-two {
    .reason {
      display: grid;
      grid-template-columns: 40% 60%;
      width: 100%;

      @media screen and (max-width: $md) {
        display: flex;
        flex-direction: column;
      }

      .image {
        position: relative;
        grid-row: 1 / span 2;
        background: url("../images/patient-doctor-consult.jpg") no-repeat;
        background-size: cover;

        @media screen and (max-width: $md) {
          order: 2;
          height: 85vw;
          width: 100%;
        }
      }

      .copy {
        &.top {
          padding: 40px 60px 80px 100px;

          @media screen and (max-width: $lg) {
            padding-right: 40px;
          }

          @media screen and (max-width: $md) {
            order: 1;
            padding: 40px;
          }
        }

        &.bottom {
          background-color: $ltgray;
          padding: 40px 60px 80px 100px;
          font-size: 1.2rem;

          @media screen and (max-width: $md) {
            order: 3;
            padding: 40px;
          }

          h4 {
            margin-bottom: 40px;
            text-align: center;
          }

          .campuses {
            display: flex;
            line-height: 1.8rem;

            @media screen and (max-width: $md) {
              flex-direction: column;
              width: 230px;
              margin: 0 auto;
            }

            .campus {
              width: 50%;
              position: relative;
              padding-left: 30px;

              &::before {
                content: "";
                display: block;
                position: absolute;
                background-image: url("../images/icon-location.svg");
                background-repeat: none;
                background-size: 100%;
                height: 30px;
                width: 24px;
                top: 0;
                left: -2px;
              }

              @media screen and (max-width: $md) {
                width: 100%;
                margin-bottom: 40px;
              }

              span {
                display: block;
              }

              &-name {
                font-weight: 800;
              }
            }
          }
        }
      }
    }
  }

  &.section-video-carousel {
    @media screen and (max-width: $md) {
      padding-bottom: 60px;
    }

    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 40%;
      height: 140px;
      width: 1px;
      border-right: 2px solid $red;
      z-index: 20;

      @media screen and (max-width: $md) {
        left: 30px;
        bottom: -30px;
      }
    }

    .spacer {
      padding-bottom: 320px;

      @media screen and (max-width: $md) {
        padding-bottom: 120px;
      }
    }

    .video-carousel {
      display: grid;
      grid-template-columns: 40% 60%;
      width: 100%;

      @media screen and (max-width: $sm) {
        grid-template-columns: 100%;
      }

      & > .copy {
        position: relative;
        overflow: hidden;

        @media screen and (max-width: $sm) {
          height: 480px;
        }

        @media screen and (max-width: $sm) and (orientation: landscape) {
          height: 450px;
        }

        @media screen and (min-width: $sm) and (max-width: $md) and (orientation: portrait) {
          height: 400px;
        }

        li {
          position: absolute;
          top: 0;
          left: 0;
          padding: 120px 100px;

          @media screen and (max-width: $lg) {
            padding: 80px 60px;
          }

          @media screen and (max-width: $sm) and (orientation: landscape) {
            padding-bottom: 0;
          }

          @media screen and (min-width: $sm) and (max-width: $md) and (orientation: portrait) {
            padding: 30px 20px;
          }

          p {
            font-size: 2.8rem;
            letter-spacing: 0.1rem;
            line-height: 2.6rem;

            @media screen and (max-width: $xl) {
              font-size: 2.2rem;
            }

            @media screen and (max-width: $lg) {
              font-size: 1.8rem;
            }

            @media screen and (max-width: $md) {
              font-size: 1.4rem;
              line-height: 1.8rem;
            }
          }
        }
      }

      & > .video {
        position: relative;
        overflow: hidden;
        height: 100%;
        width: 100%;
        z-index: 4;

        @media screen and (max-width: $sm) and (orientation: landscape) {
          height: 80px;
        }

        @media screen and (min-width: $sm) and (max-width: $md) and (orientation: portrait) {
          height: 400px;
        }

        .counter {
          position: relative;
          position: absolute;
          z-index: 3;
          bottom: 30px;
          left: 30px;
          background-color: $red;
          color: $white;
          font-weight: 800;
          border-radius: 40px;
          height: 80px;
          width: 80px;
          cursor: pointer;
          transition: 1s;
          user-select: none;

          @media screen and (max-width: $sm) and (orientation: landscape) {
            top: 40px;
            right: 40px;
            left: auto;
          }

          &::after {
            content: "";
            position: absolute;
            width: 22px;
            height: 12px;
            top: 35px;
            right: 10px;
            background-image: url("../images/icon-chevron.svg");
            background-repeat: none;
            background-size: 100%;
            transform: rotate(-90deg);
          }

          &.active {
            opacity: 1;
            top: 0;
            left: 0;
            margin-left: -40px;
            margin-top: -40px;
            transition: 0s;
          }

          .count {
            position: absolute;
            top: 27px;
            left: 15px;
            width: 100%;
            font-size: 1.5rem;
          }
        }

        & > img {
          position: relative;
          z-index: 2;
          width: 100%;
        }

        ul {
          display: block;
          overflow: hidden;
          width: 100%;
          margin: 0;

          @media screen and (min-width: $sm) and (max-width: $md) and (orientation: portrait) {
            height: 200px;
          }

          &::after {
            content: "";
            display: block;
            width: 100%;
            height: 3px;
            position: absolute;
            bottom: 0;
            background-color: $white;
          }

          @media screen and (max-width: $sm) and (orientation: landscape) {
            display: none;
          }

          li {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;

            @media screen and (min-width: $sm) and (max-width: $md) and (orientation: portrait) {
              height: 400px;
            }

            img {
              width: 100%;
              margin-bottom: -3px;
            }
          }
        }
      }
    }
  }

  &.section-horizontal-scroll {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-top: 50px;
    display: block;
    z-index: 3;

    .progress-bar {
      position: relative;
      position: absolute;
      width: 100%;
      height: 10px;
      background-color: $pink;
      bottom: 0;

      .progress {
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 25px;
        height: 10px;
        background-color: $red;
      }
    }

    .panels {
      ul {
        width: 3200px;
        display: flex;

        @media screen and (max-width: $sm) {
          width: 1600px;
        }

        @media screen and (max-width: $sm) and (orientation: landscape) {
          width: 2000px;
        }

        li {
          width: 800px;
          padding-left: 100px;
          padding-bottom: 140px;

          @media screen and (max-width: $md) {
            width: 550px;
            padding-left: 80px;
            padding-bottom: 40px;
          }

          @media screen and (max-width: $sm) {
            width: 400px;
            padding-left: 80px;
            padding-bottom: 40px;
          }

          @media screen and (max-width: $sm) and (orientation: landscape) {
            width: 600px;
          }

          &.panel-title {
            h2 {
              position: relative;
              text-align: right;

              @media screen and (max-width: $sm) {
                text-align: left;
                margin-left: -55px;
              }

              span.revealing-header-mask {
                display: block;
              }
            }
          }

          &.panel-quote {
            padding-right: 65px;

            .quote {
              font-size: 2rem;
              font-weight: 100;
              line-height: 2.4rem;
              position: relative;

              @media screen and (max-width: $md) {
                font-size: 1.4rem;
                line-height: 1.7rem;
              }

              @media screen and (max-width: $sm) and (orientation: landscape) {
                font-size: 1.2rem;
                line-height: 1.4rem;
              }

              &::before {
                position: absolute;
                content: "";
                width: 32px;
                height: 26px;
                background-image: url("../images/icon-double-quote.png");
                background-size: cover;
                top: 0;
                left: -50px;
              }
            }

            .patient {
              margin-top: 40px;
              display: grid;
              grid-template-columns: 140px auto;
              width: 100%;
              align-items: center;

              @media screen and (max-width: $sm) {
                grid-template-columns: 90px auto;
              }

              .left {
                width: 140px;

                @media screen and (max-width: $sm) {
                  width: 90px;
                }
              }

              .right {
                font-size: 1.4rem;

                @media screen and (max-width: $sm) {
                  font-size: 1rem;
                }

                span {
                  display: block;
                  padding-left: 20px;

                  &.name {
                    font-weight: 800;
                    line-height: 2rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.section-icon-grid {
    background-color: $ltgray;
    padding: 120px 30px;

    @media screen and (max-width: $sm) {
      padding: 60px 30px;
    }

    .container {
      padding: 40px 0;

      h4 {
        text-align: center;
        margin-top: -140px;
        margin-bottom: 100px;
      }

      h3 {
        text-align: center;
        font-size: 2.8rem;
        font-weight: 400;

        &::after {
          display: none;
        }

        @media screen and (max-width: $md) {
          font-size: 2.4rem;
        }
      }

      .facts {
        display: grid;
        grid-template-columns: auto auto auto;
        width: 100%;
        max-width: $lg;
        margin: 0 auto;
        text-align: center;

        @media screen and (max-width: $md) {
          grid-template-columns: auto auto;
        }

        @media screen and (max-width: $sm) {
          grid-template-columns: auto;
          text-align: left;
        }

        li {
          padding: 40px 45px;

          @media screen and (max-width: $sm) {
            display: flex;
            padding: 30px 15px;
          }

          span {
            display: block;
            font-size: 1.45rem;
            line-height: 1.8rem;

            @media screen and (max-width: $sm) {
              font-size: 1.2rem;
              width: 100%;
            }

            > span.small {
              font-size: 1rem;
            }

            &.icon-pop {
              height: 75px;

              @media screen and (max-width: $sm) {
                width: 75px;
                padding-right: 20px;
              }

              .icon {
                width: 20px;
                opacity: 0;
                text-align: center;
                transition: transform 1.5s cubic-bezier(0.16, 1.08, 0.38, 0.98) 0.1s;

                @media screen and (max-width: $sm) {
                  margin-top: 10px;
                }
              }

              &.is-active {
                .icon {
                  transform: scale(3.5);
                  opacity: 1;
                }
              }
            }

            strong {
              display: block;
              margin-bottom: 0.5rem;
            }
          }
        }
      }
    }
  }

  &.section-side-by-side {
    .side-by-side {
      display: flex;
      width: 100%;

      @media screen and (max-width: $md) {
        display: block;
      }

      & > div {
        display: flex;
        width: 50%;
        height: 35vw;
        text-align: center;
        padding: 40px;
        color: $white;
        background-size: cover;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: $md) {
          width: 100%;
          height: 100vw;
        }

        a {
          color: $white;
        }

        &.left {
          font-size: 1.8rem;
          font-weight: 800;
          background-image: url("../images/cancer-cell.jpg");

          a {
            font-size: 2.4rem;
            font-family: $fontBlack;
            font-weight: 800;

            .letters {
              font-size: 2rem;
            }
          }
        }

        &.right {
          background-image: url("../images/see-the-difference.jpg");

          span {
            font-size: 1.2rem;
            font-family: $fontBlack;
            font-weight: 800;
            letter-spacing: 0.2rem;
            text-transform: uppercase;
            line-height: 1.6rem;

            @media screen and (max-width: $sm) {
              font-size: 1rem;
              letter-spacing: 0.1rem;
              line-height: 1.4rem;
            }
          }
        }

        & > div > span {
          display: block;
          margin-bottom: 0.6rem;
        }
      }
    }
  }

  &.section-directors {
    margin-top: 150px;
    text-align: center;
    background-color: $white;

    .quote {
      font-size: 1.65rem;
      line-height: 2.6rem;
      width: 50%;
      max-width: 1000px;
      margin: 20px auto;
      position: relative;

      @media screen and (max-width: $lg) {
        width: 80%;
      }

      @media screen and (max-width: $md) {
        font-size: 1.4rem;
        line-height: 1.6rem;
      }

      &::before,
      &::after {
        position: absolute;
        content: "";
        width: 32px;
        height: 26px;
        background-image: url("../images/icon-double-quote.png");
        background-size: cover;
        top: 0;
        left: -40px;

        @media screen and (max-width: $md) {
          width: 24px;
          height: 20px;
        }
      }

      &::after {
        left: auto;
        right: -40px;
        transform: rotate(180deg);
      }
    }

    .directors {
      display: flex;
      width: 80%;
      max-width: 1000px;
      margin: 20px auto;

      @media screen and (max-width: $lg) {
        display: block;
      }

      @media screen and (max-width: $sm) {
        width: 100%;
      }

      li {
        position: relative;
        padding: 60px 90px 60px 180px;
        width: 50%;

        @media screen and (max-width: $lg) {
          width: 100%;
        }

        @media screen and (max-width: $sm) {
          padding: 40px 30px 40px 120px;
        }

        &:first-child {
          &::after {
            content: "";
            display: block;
            position: absolute;
            width: 1px;
            height: 240px;
            border-left: 2px solid $gray;
            top: -20px;
            right: 0;

            @media screen and (max-width: $lg) {
              display: none;
            }
          }
        }

        span {
          display: block;
          text-align: left;
          margin-bottom: 0.6rem;
          line-height: 1.4rem;

          &.name {
            font-weight: 800;
          }
        }

        img {
          width: 90px;
          position: absolute;
          left: 70px;
          top: 20px;

          @media screen and (max-width: $sm) {
            left: 15px;
            top: 15px;
            width: 80px;
          }
        }
      }
    }
  }

  &.section-red-block {
    background-color: $red;
    color: $white;
    padding: 120px 100px;
    background-image: url("../images/downtown-cincinnati.png");
    background-size: cover;
    background-position: top right;

    @media screen and (max-width: $md) {
      padding: 120px 60px;
    }

    @media screen and (max-width: $md) {
      padding: 60px 30px;
      background-size: 320%;
      background-repeat: no-repeat;
      background-position: 65% 100%;
    }

    & > div {
      max-width: 560px;
      font-size: 1.2rem;
      letter-spacing: 0.1rem;

      h2 {
        color: $white;
        font-size: 1rem;
        letter-spacing: 0.3rem;
        line-height: 1.5rem;
        margin-bottom: 0.8rem;
      }

      p {
        line-height: 1.5rem;
      }
    }
  }
}
