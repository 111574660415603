@import url("https://cloud.typography.com/7449276/7924812/css/fonts.css");

body {
  color: $primary-text-color;
  font-family: $fontBase;
  font-size: 16px;
}

strong {
  font-weight: 900;
}

h1 {
  font-size: 1.5rem;
  text-transform: uppercase;
}

h2 {
  font-size: 2.25rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.8rem;
}
