$black: #000;
$primary-text-color: $black;
$white: #fff;
$ltgray: #f6f7f6;
$gray: #e4e4e4;
$ltblue: #def1fc;
$red: #e00122;
$pink: #f4a1ae;
$blue: #305bac;

$xs: 480px;
$sm: 760px;
$md: 960px;
$lg: 1240px;
$xl: 1920px;
$xxl: 2480px;
$fontBase: "Verlag A", "Verlag B", verlag, sans-serif;
$fontBlack: "Verlag Black", "Verlag A", "Verlag B", verlag, sans-serif;
